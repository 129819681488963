html,body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

body {
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

@font-face {
  font-family: 'Amsterdam Sans Armen';
  font-weight: normal;
  src: local('Amsterdam Sans Armen'), url(./assets/fonts/ttf/AmsterdamSans-Regular.ttf) format('truetype')
}

@font-face {
  font-family: 'Amsterdam Sans Armen';
  font-weight: bold;
  src: local('Amsterdam Sans'), url(./assets/fonts/ttf/AmsterdamSans-Bold.ttf) format('truetype')
}

@font-face {
  font-family: 'Amsterdam Sans Armen';
  font-weight: normal;
  font-style: italic;
  src: local('Amsterdam Sans Armen'), url(./assets/fonts/ttf/AmsterdamSans-Italic.ttf) format('truetype')
}

@font-face {
  font-family: 'Amsterdam Sans Armen';
  font-weight: bold;
  font-style: italic;
  src: local('Amsterdam Sans Armen'), url(./assets/fonts/ttf/AmsterdamSans-BoldItalic.ttf) format('truetype')
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
